:root {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background 0.2s ease-out;
  font-size: 1.6rem;
}

span {
  line-height: normal;
}